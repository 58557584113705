


































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Vue, Component, Watch } from 'vue-property-decorator';
import { prefix } from '@/config/global';
import secondaryMenu from '@/components/secondaryMenu/index.vue';
import loginDropdown from '@/components/loginDropdown/index.vue';
import logo from '@/components/logo/index.vue';
import navMenu from '@/components/navMenu/index.vue';
import { permissionModule } from '@/store/modules/permission';

@Component({
  components: {
    loginDropdown,
    navMenu,
    secondaryMenu,
    logo,
  },
})
export default class ManageLayout extends Vue {
  $router: any;
  $refs: any;
  prefix: string = prefix;
  $jQ: any;
  $domainConfig: any;
  loginQList = [
    {
      value: '返回系统',
      label: '/system',
    },
    {
      value: '退出',
      label: '/logout',
    },
  ];
  lRouterId = 0;
  routerId = 0;
  currentValue = String(this.routerId);
  childrenList: any = [];

  get menuList(): any {
    return permissionModule.routes[3].children;
  }
  mounted(): void {
    this.initRouterChangeHandler();
  }
  // 头部导航栏change
  selectChangeHandler(val: string): void {
    this.currentValue = val;
  }
  // 导航栏changer执行代码
  initRouterChangeHandler(): void {
    let val = this.$route.path;
    if (val === '/manage') {
      val = this.menuList[0].path;
    }
    this.currentValue = val;
    this.$router.push(val);
    sessionStorage.setItem('manageRouterPath', val);
  }

  @Watch('menuList', { immediate: true, deep: true })
  changeMenulist(): void {
    this.initRouterChangeHandler();
  }
}
